import React, { useEffect, useRef, useState } from "react";
import { ImagePath } from "../helper/ImagePath";
import "./Dashboard.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  emptyTotalEarning,
  getAllEarning,
  getAllLeadsUnconvertedListApi,
  getLeadSummaryConvUnconv,
  getMonthlyOpnedTradeApi,
  getRefferlLInkCount,
  postLogin,
  removeLoginData,
} from "../store/actions";
import {
  currentMonth,
  dateFormaterForApi,
  endDateSelectionFilter,
  getMonths,
  last3Months,
  last6Months,
  lastMonthFirstDay,
  lastMonths,
  setUserCode,
} from "../helper/helpingMethods";
import dayjs from "dayjs";
import MonOpenTradedChart from "../allCharts/MonOpenTradedChart";
import CustomModal from "../components/CustomModal";
import { json, useLocation, useNavigate } from "react-router-dom";
import TotalLeadsChartDashboard from "../allCharts/TotalLeadsChartDashboard";
import moment from "moment";
import IncomeOverViewPieChart from "../allCharts/IncomeOverViewPieChart";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import Pagintion from "../common/Pagination";
import CarouselDashboard from "../common/CarouselDashboard";
import loader from "../assets/gif/loaderIncome.gif"
import axios from "axios";
import decrypted from "../helper/decrpted";
import incrpted from "../helper/incrpted";

const Dashboard = () => {
  const CurrentLocation = useLocation();
  const Navigate = useNavigate();
  // let uniqueCode = getUserCode();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(localStorage.getItem("@month") == 'last_month' ? lastMonths : localStorage.getItem("@month") == "current_month" ? currentMonth : localStorage.getItem("@month") == 'last_three_month' ? last3Months : localStorage.getItem("@month") == 'last_six_month' ? last6Months : localStorage.getItem("@month") == "current_month" ? currentMonth : localStorage.getItem("@month") == 'last_three_month' ? last3Months : currentMonth);
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [toggleValue, setToggleValue] = useState("income");
  const [monOpenedTradedChartData, setMonOpenedTradedChartData] = useState([]);
  const [radioSelectedValue, setRadioSelectedValue] = useState("client");
  const [shareLink, setShareLink] = useState({ open: false, selectedItem: null });
  const message = "Bigul Alert: You're nearly there! Complete your KYC to unlock all features. Just a few steps left . Finish it now:";
  const [monOpenTradedFilterValue, setMonOpenTradedFilterValue] = useState(
    "last_three_month"
  )
  const [filterTotalEarned, setFilterTotalEarned] = useState(
    "last_three_month"
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let convertedLeads = 0;
  let unconvertedLeads = 0;
  let totalLeads = 0;
  let stageWiseSteps;

  const dispatch = useDispatch();
  useEffect(() => {
    const searchParamsString = CurrentLocation?.search?.substring(1);
    const paramsArray = searchParamsString.split('&');
    let userName, password;
    paramsArray.forEach(param => {
      const [key, value] = param.split(':');
      if (key === 'UserName') {
        userName = value;
      }
    });
    if (userName) {
      const valueToSet = (userName) ? 1 : 0;
      localStorage.setItem('myValue', valueToSet.toString());
      localStorage.setItem("Slider", JSON.stringify("1"));
      checkTokenAppUser({ userName })
    }
  }, [CurrentLocation.search])

  const checkTokenAppUser = async ({ userName }) => {
    const url = `https://apinew.bigul.app:4040/api/v1/verify-token`;
    // const payload = { token: "eyJpdiI6InZrOVY5RXNqNU9pWXY3YndKdjR6K3c9PSIsInZhbHVlIjoiNm10RzR4emQ2STUrRnVma05vVHNFQT09IiwibWFjIjoiN2Y0Y2RlNjY4OGExNGY1ZmFiNzM3MTJjZWQ2NTU1MTk0MTdkZjI3NmE0NmUwMTU1NmJhMjJmMWJkYjA3MzNmNCIsInRhZyI6IiJ9" };
    const payload = { token: userName };
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNkMDUxZGExMDc2MDg1NzVkOGE2NDZhZWM5ZGM0NTI1NGRiYjE4ZjM0OThiOThhZDYwYWI2MDI1ZGU2MjRlZDJhOGIxZTk5YTQ2OTk4NDJiIn0.eyJhdWQiOiI1IiwianRpIjoiY2QwNTFkYTEwNzYwODU3NWQ4YTY0NmFlYzlkYzQ1MjU0ZGJiMThmMzQ5OGI5OGFkNjBhYjYwMjVkZTYyNGVkMmE4YjFlOTlhNDY5OTg0MmIiLCJpYXQiOjE2ODk4NTM5NDYsIm5iZiI6MTY4OTg1Mzk0NiwiZXhwIjoxNzIxNDc2MzQ2LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.FBVXv3ilHKnz0t_I5B1tg54kjwVzM59PMIVCH5F7NZtwqxumiq2I8HsNajHw1w-2oVxlkgHZHvHAx9TmlAJFC55ffdNo3URC3At4_nqMPMsJ2IhO-1FXEqX5iDGN7F3Fr42chvNw3caB7dNBG207zgpJm7XpK-18iqVm6oW034hwKHg8uHz8BhPd_0DW8i9ouQf0RI7BW-W6Q6tQtNyIcLwLrLunQtzAci4rqDaOViESqiCL8bb0ZLtNAtY_DsbLUzOQ1NJF5R9xLhH_yughbeNEdmQgKjuueDBKkiPWvqxZX4GdqtvZKxt4ZgpAHR2m8CvhYWL2HbwmSl9TiYDAvKcAi8gGgI06HodxToP1j2KftfG2c4tTrJ9i2hGbD9o7eV12EQ0ZnMbBdyCc-5GW-hxXoz3j1PDyBRzVGYquihseVoIuOuk_6NieVwc0a7DOJOlpt4nJjttdRQGt5D9f_0_LB89pUwUXuJIKQ5DKWVPpT6PrX5YHxwa4rIDC3_8mXcT6w9qF2TbQwjU7K-baRanWqpHZT9v-d0Mc2GA2aGIIAl_urhGcMLDiMwkEv15OcZ5pBn190s4M0HcoelVKis7cf4PeOcBkgAPUfml-yJlNUWOnFNYVABI0wjdj0NrYki5vLdNt8ZoVsQ9PyNBRWD0815tSmGbVkILJEdvJ-vc"; // Apna token yahan par daalein
    const headers = {
      'Authorization': `Bearer ${token}`
    };
    try {
      const response = await axios.post(url, payload, { headers });
      console.log("response checke api Dashbiard otp>>", response.data.userName);
      if (response.status == true) {

        let ttt = response?.data
        response?.status && localStorage.setItem("userData", JSON.stringify(ttt))
        Navigate("/console")
      }
    } catch (error) {
      console.error('Error:', error.message);
      return null;
    }
  };

  //*****************************************Local data get client code*************************************************** */
  // const userData = JSON.parse(localStorage.getItem("userData"));
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const decryptedResult = decrypted(userData);

  const uniqueCode = decryptedResult?.username
  useEffect(() => {
    const duration = localStorage.getItem("@month") == null ? "current_month" : localStorage.getItem("@month")
    const link = "client"
    console.log("enc", incrpted([{
      duration: duration,
      refcode: uniqueCode,
      client_code: uniqueCode
    }]));

    dispatch(
      getRefferlLInkCount({
        code: uniqueCode,
        link_type: link,
      })
    );
    dispatch(
      getAllEarning({
        'enc': incrpted([{
          duration: duration,
          refcode: uniqueCode,
          client_code: uniqueCode
        }])
      })
    );
    const payload = {
      'enc': incrpted([{
        duration: duration,
        refcode: uniqueCode,
        client_code: uniqueCode
      }])

    }

    dispatch(
      getLeadSummaryConvUnconv(payload)
    );
    dispatch(getAllLeadsUnconvertedListApi({
      'enc': incrpted([{
        duration: duration,
        refcode: uniqueCode,
        limit: 10,
        start_date: "",
        end_date: "",
        page: 1,
        client_code: uniqueCode
      }])
    }))
    dispatch(getMonthlyOpnedTradeApi({
      'enc': incrpted([{
        duration: duration,
        refcode: uniqueCode,
        client_code: uniqueCode
      }])

    }))
    // dispatch(
    //   getStagewiseUnconvertedLeads({
    //     duration: duration,
    //     refcode: uniqueCode,
    //   })
    // );
  }, [uniqueCode]);




  const handleAccountTradeEarnFilter = (e) => {
    const selectedValue = e.target.value;
    const lastMonthDate = selectedValue == "last_month" ? "last_month_date" : selectedValue
    const toDate = endDateSelectionFilter(selectedValue);
    const calenderDateStart = endDateSelectionFilter(lastMonthDate)
    const a = dayjs(calenderDateStart);
    if (lastMonthDate === "last_month_date") {
      const dateParts = moment(lastMonthFirstDay).format("DD-MM-YYYY").split('-');
      const lastDateOfMonth = dayjs()
        .set('year', parseInt(dateParts[2], 10))
        .set('month', parseInt(dateParts[1], 10) - 1)
        .endOf('month');
      setEndDate(dayjs(lastDateOfMonth))
    } else {
      setEndDate(dayjs(new Date()))
    }
    const duration = selectedValue;
    const refcode = uniqueCode
    setStartDate(a);
    // allUnconvertedList({ duration })
    dispatch(emptyTotalEarning());
    localStorage.setItem('@month', duration)
    dispatch(
      getAllEarning({
        'enc': incrpted([{
          duration: duration,
          refcode: uniqueCode,
          client_code: uniqueCode
        }])


      })
    );

    dispatch(
      getLeadSummaryConvUnconv({
        'enc': incrpted([{
          duration: duration,
          refcode: uniqueCode,
          client_code: uniqueCode
        }])
      })
    );
    dispatch(getMonthlyOpnedTradeApi({
      'enc': incrpted([{
        duration: duration,
        refcode: uniqueCode,
        client_code: uniqueCode
      }])
    }))
    dispatch(getAllLeadsUnconvertedListApi({
      'enc': incrpted([{
        duration: duration,
        refcode: uniqueCode,
        limit: 10,
        start_date: "",
        end_date: "",
        page: 1,
        client_code: uniqueCode
      }])
    }))
  };
  //************************************all api response get ****************************************** */

  const { totalEarning } = useSelector((state) => ({
    totalEarning: state.totalEarning,
  }));
  const { leadSummaryConvUnconvData } = useSelector((state) => ({
    leadSummaryConvUnconvData: state.leadSummaryData,
  }));

  const { stageWiseUnconvLeadsData } = useSelector((state) => ({
    stageWiseUnconvLeadsData: state.stagewiseUnconvLeads,
  }));

  const { getUnConvertedLIst } = useSelector((state) => ({
    getUnConvertedLIst: state?.getUnConvertedLIst?.getUnConvertedLIst
  }))

  //referal link api response
  const { refferalData } = useSelector((state) => ({
    refferalData: state?.refferalData?.refferalData,
  }));

  const { MonthlyOpendTrade } = useSelector((state) => ({
    MonthlyOpendTrade: state?.MonthlyOpendTrade?.MonthlyOpendTrade
  }))


  let itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index of the first and last items to be displayed on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getUnConvertedLIst && getUnConvertedLIst?.data?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPagesPL = Math.ceil(getUnConvertedLIst && getUnConvertedLIst?.data?.length / itemsPerPage);



  if (stageWiseUnconvLeadsData?.stagewiseUnconvLeads?.length != 0) {
    const withoutEsign = stageWiseUnconvLeadsData?.stagewiseUnconvLeads?.filter(
      (item) => item?.dropout_step != 10
    );
    stageWiseSteps = removeDuplicates(withoutEsign);
  }

  function removeDuplicates(array) {
    const uniqueItems = {};
    array?.forEach(item => {
      const { dropout_step, dropout_count } = item;
      if (!uniqueItems[dropout_step]) {
        uniqueItems[dropout_step] = { ...item }; // Clone the object to avoid reference issues
      } else {
        uniqueItems[dropout_step].dropout_count = (parseInt(uniqueItems[dropout_step].dropout_count) || 0) + (parseInt(dropout_count) || 0);
      }
    });

    const uniqueArray = Object.values(uniqueItems);
    return uniqueArray;
  }
  const onChangeStartDate = (date) => {
    let end = endDate;
    setStartDate(date.$d);
    let dateSelected = date.$d;
    const duration = "custom_date_range";
    const refcode = uniqueCode;
    const start_date = moment(dateSelected).format("YYYY-MM-DD");
    const end_date = moment(end).format("YYYY-MM-DD");
    dispatch(
      getLeadSummaryConvUnconv({
        'enc': incrpted([{
          duration: "custom_date_range",
          start_date: moment(dateSelected).format("YYYY-MM-DD"),
          end_date: moment(end).format("YYYY-MM-DD"),
          client_code: uniqueCode
        }])
      })
    );
    // dispatch(
    //   getStagewiseUnconvertedLeads({
    //     duration,
    //     start_date,
    //     end_date,
    //     refcode,
    //   })
    // );
  };

  const onChangeToDate = (date) => {
    setEndDate(date.$d);
    let dateSelected = date.$d;
    const duration = "custom_date_range";
    const start_date = moment(startDate).format("YYYY-MM-DD"); // Use startDate directly
    const end_date = moment(dateSelected).format("YYYY-MM-DD");
    const refcode = uniqueCode;
    dispatch(

      getLeadSummaryConvUnconv({
        'enc': incrpted([{
          duration: "custom_date_range",
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(dateSelected).format("YYYY-MM-DD"),
          client_code: uniqueCode
        }])
      })
    );
    // dispatch(
    //   getStagewiseUnconvertedLeads({
    //     duration,
    //     start_date,
    //     end_date,
    //     refcode,
    //   })
    // );
  };



  const handleReferralLinkRadio = (e) => {
    const value = e.target.value;
    setRadioSelectedValue(value);
    if (value == "client") {
      dispatch(
        getRefferlLInkCount({
          code: uniqueCode,
          link_type: value
        })
      );
    } else {
      dispatch(
        getRefferlLInkCount({
          code: uniqueCode,
          link_type: value
        })
      );
    }
  };

  const phoneEncripted = (phoneNumber) => {
    return phoneNumber.replace(/(\d{6})(\d{4})/, "******$2");
  };

  const leadSummConvUnconvLeadsData = leadSummaryConvUnconvData?.leadSummaryData;

  if (
    (leadSummConvUnconvLeadsData.converted &&
      leadSummConvUnconvLeadsData.unconverted) != null
  ) {
    convertedLeads = parseInt(leadSummConvUnconvLeadsData.converted);
    unconvertedLeads = parseInt(leadSummConvUnconvLeadsData.unconverted);
    totalLeads = parseInt(leadSummConvUnconvLeadsData.total_kyc);
  }

  const [isCustomModalOpened, setIsCustomModalOpened] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");

  const customModalOpened = () => {
    setIsCustomModalOpened(true);
  };
  const customModalClosed = () => {
    setIsCustomModalOpened(false);
  };
  const handleCustomModalOpen = (phoneNumber) => {
    customModalOpened();
    setIsModalOpen(true);
    setSelectedPhoneNumber(phoneNumber);
  };

  const handleCustomModalClose = () => {
    customModalClosed()
  };


  const handleViewMore = (cardValue) => {
    // Navigate(cardValue);
    Navigate(cardValue, { state: "All" })
  };

  const handleMonthlyOpenedTradedAccount = (data) => {
    const monthlyData = {};

    data?.forEach((item) => {
      const { name, Opened, Traded } = item;
      if (!monthlyData[name]) {
        monthlyData[name] = {
          Opened: 0,
          Traded: 0,
        };
      }
      monthlyData[name].Opened += parseFloat(Opened);
      monthlyData[name].Traded += parseFloat(Traded);

    });

    const sortedData = Object.entries(monthlyData).map(
      ([monthYear, value]) => ({
        name: monthYear,
        Opened: Number(value?.Opened)?.toFixed(2), // or +value.Opened.toFixed(2)
        Traded: Number(value?.Traded)?.toFixed(2),
      })
    );

    return sortedData;
  };

  useEffect(() => {
    if (
      MonthlyOpendTrade?.length !== 0 &&
      MonthlyOpendTrade
    ) {
      let months = [];
      let monthlyOpenedTradedAcc = [];
      let totalMonths;

      MonthlyOpendTrade && MonthlyOpendTrade?.map((item) => {
        const accountData = {
          name: getMonths(`${item?.year_id}-${item?.month_id}`),
          Opened: item?.account_open_count,
          Traded: item?.account_trade_count,
        };
        months.push(`${item?.year_id}-${item?.month_id}`);

        monthlyOpenedTradedAcc.push(accountData);

        totalMonths = [...new Set(months)];
      });
      const monthlyData = handleMonthlyOpenedTradedAccount(
        monthlyOpenedTradedAcc
      );
      setMonOpenedTradedChartData(monthlyData);
    } else {
      setMonOpenedTradedChartData([]);
    }
  }, [MonthlyOpendTrade]);

  //Total Income || Total Earning
  const numberString = totalEarning?.totalEarning?.earning_with_brok_subs;

  const number = numberString?.replace(/,/g, '');
  let forNumTOtalEarning;
  if (number !== undefined && number !== null && !isNaN(number)) {
    forNumTOtalEarning = parseFloat(number)?.toLocaleString('en-IN', { maximumFractionDigits: 2, useGrouping: true });
  }
  //brokrage earning
  const brokrageValue = totalEarning?.totalEarning?.brokerage_earning
  const brokerageEarning = brokrageValue?.replace(/,/g, '');
  let brokarageEarnValue;
  if (brokerageEarning !== undefined && brokerageEarning !== null && !isNaN(brokerageEarning)) {
    brokarageEarnValue = parseFloat(brokerageEarning)?.toLocaleString('en-IN', { maximumFractionDigits: 2, useGrouping: true });
  }
  //SbsCiption Income
  const subScriptionValue = totalEarning?.totalEarning?.subscription_earning
  let subscriptionEarningValue;
  if (subScriptionValue !== undefined && subScriptionValue !== null && !isNaN(subScriptionValue)) {
    subscriptionEarningValue = parseFloat(subScriptionValue)?.toLocaleString('en-IN', { maximumFractionDigits: 2, useGrouping: true });
  }


  const Loader = () => (
    <div className="loader-container">
      <img src={loader} alt="Loading..." className="loader-img" style={{ width: '50px', height: 'auto' }} />
    </div>
  );
  const myValue = localStorage.getItem('myValue');
  return (
    <>
      <div className="container main-dive">
        <div className="uncoveredLead_filter d-flex justify-content-between mb-2">
          <div className="greetings">
          </div>
          <div className="">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => handleAccountTradeEarnFilter(e)}
              style={{ cursor: 'pointer' }}
              value={localStorage.getItem("@month")}
            >
              <option value="current_month" >Current Month</option>
              <option value="last_month">Last Month</option>
              <option value="last_three_month">Last 3 Months</option>
              <option value="last_six_month">Last 6 Months</option>
              {/* <option value="one_year">Last 12 Months</option> */}
            </select>
          </div>
        </div>
        {/* <button onClick={handleButtonClick}>Empty Total Earning</button> */}
        <div className="total_ac">
          <div className="row">
            <div className="col-lg-4  mb-4">
              <div className="card_wr statatics-right-bg">
                <div className="total_ac_card">
                  <div className="total_ac_card_image total_ac_image">
                    <img
                      src={ImagePath.totalAccountIcon}
                      alt="user"
                    />
                  </div>
                  <div className="d-flex ms-3 card_text_wr w-100">
                    <div>
                      <span>Total Leads</span>
                    </div>
                    <div className="view_more_wr">
                      <span className="noOf_count">
                        {totalEarning?.totalEarning == "" ?
                          <Loader />
                          :
                          totalEarning?.totalEarning?.total_leads}
                        {/* {totalEarning?.totalEarning == "" ? 0 : totalEarning?.totalEarning?.total_leads} */}
                        {/* <Loader /> */}
                      </span>
                      <span
                        className="view_more_text"
                        onClick={() => {
                          handleViewMore("/AllLeads");

                        }}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                      >
                        View
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mb-4">
              <div className="card_wr statatics-right-bg">
                <div className="total_ac_card">
                  <div className="total_ac_card_image total_trade_ac">
                    <img src={ImagePath.totalTradeIcon} alt="user" />
                  </div>
                  <div className="d-flex ms-3 card_text_wr w-100">
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span>Total Accounts Opened</span>
                    </div>
                    <div className="view_more_wr">
                      <span className="noOf_count">
                        {/* {totalEarning?.totalEarning == "" ? 0 :
                          totalEarning?.totalEarning?.account_opened_count} */}
                        {totalEarning?.totalEarning == "" ?
                          <Loader /> :
                          totalEarning?.totalEarning?.account_opened_count}
                      </span>
                      <span
                        className="view_more_text"
                        onClick={() => {
                          handleViewMore("/totalTradedIncome");
                        }}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                      >
                        View
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mb-4">
              <div className="card_wr statatics-right-bg">
                <div className="total_ac_card">
                  <div className="total_ac_card_image total_earning">
                    <img src={ImagePath.totalEarning} alt="user" />
                  </div>
                  <div className="d-flex ms-3 card_text_wr w-100">
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span>Total Business Points</span>
                    </div>
                    <div className="view_more_wr">
                      <span className="noOf_count">
                        {totalEarning?.totalEarning == "" ? <Loader /> : forNumTOtalEarning}
                        {/* " ₹ " */}
                      </span>
                      <span
                        className="view_more_text"
                        onClick={() => {
                          handleViewMore("/IncomeScreen");
                        }}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                      >
                        View
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main_body_content mb-4">
          <div className="row">
            <div className="col-xl-4">
              <div className='lead_summry_container bg-white' >
                <div className="greetings mb-4">
                  <h3>Lead Summary</h3>
                  <h5></h5>
                  <div className="date_picker_wr">
                    {/* <div className="date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From Date"
                          format="DD/MM/YYYY"
                          value={startDate}
                          maxDate={dayjs(new Date())}
                          // minDate={new Date()}
                          onChange={onChangeStartDate}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To Date"
                          format="DD/MM/YYYY"
                          maxDate={dayjs(new Date())}

                          value={endDate}
                          onChange={onChangeToDate}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div> */}
                    <div className="date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From Date"
                          format="DD/MM/YYYY"
                          value={dayjs(startDate)}
                          maxDate={dayjs(new Date())}
                          onChange={onChangeStartDate}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To Date"
                          format="DD/MM/YYYY"
                          maxDate={dayjs(new Date())}
                          value={endDate}
                          onChange={onChangeToDate}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <TotalLeadsChartDashboard
                  converted={convertedLeads}
                  unconverted={unconvertedLeads}
                  total={totalLeads}
                  sumofCOn={(leadSummConvUnconvLeadsData?.converted) + (leadSummConvUnconvLeadsData?.unconverted)}
                />
                <div className="line"></div>
                {/* <StageWiseUnconvertedLeads stageWiseSteps={stageWiseSteps} /> */}
              </div>
            </div>

            <div className="col-xl-8 refferal_link_section">
              <div >
                <div
                  className={myValue === "1" ? "lead_summry_container referral_wr mobile-d-none" : "lead_summry_container referral_wr"}
                  style={{
                    backgroundImage: `url(${ImagePath.referralBackground})`,
                  }}
                >
                  <div className="row">
                    <div className="col-lg-9 ">
                      <div className="referral_text_wr">
                        <div className="row">
                          <span className="referral_text">
                            Generate Link
                          </span>
                          <div className="d-flex p-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                value={"client"}
                                // checked
                                checked={radioSelectedValue === "client"}
                                onChange={(e) => handleReferralLinkRadio(e)}
                              />
                              <label
                                className="referral_text"
                                for="flexRadioDefault1"
                                style={{ cursor: 'pointer' }}
                              >
                                Refer a client
                              </label>
                            </div>
                            {/* <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                value={"partner"}
                                checked={radioSelectedValue === "partner"}
                                onChange={(e) => handleReferralLinkRadio(e)}
                              />
                              <label
                                className="referral_text"
                                for="flexRadioDefault2"
                                style={{ cursor: 'pointer' }}
                              // style={{ color: 'white' }}
                              >
                                Refer a partner
                              </label>
                            </div> */}
                          </div>
                          <div>
                            <div className="refrel_links_wr">
                              <input
                                type="text"
                                value={refferalData == "" ? 0 : refferalData?.link}
                                // onChange={handleInputChange}
                                placeholder="file/CuMBgdkstbnerfimcidneilwqm//oit..."
                                className="custom-input"
                              />
                              <button
                                className="custom-button"
                                onClick={() => handleCustomModalOpen("")}
                              >
                                <div className="share_wr">
                                  {/* <img src={ImagePath.shareIcon} /> */}
                                  <span>Share</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 share_image_wr">
                      <img
                        className="img img-fluid"
                        src={ImagePath.shareImage}
                        alt="shares"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="uncovered_data_wr">
                <div className="uncoveredLead_filter d-flex justify-content-between">
                  <h5 className="pe-3">Unconverted Leads</h5>
                </div>
                <div className="lead_data_wr">
                  <div style={{ height: "auto", overflowY: "auto" }}>
                    {
                      currentItems?.length !== 0 ?
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Names</th>
                              <th scope="col" style={{ width: '250px' }}>Mobile Number</th>
                              <th scope="col">Status</th>
                              <th scope="col" className={myValue === "1" ? "mobile-d-none" : ""}
                              >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item?.full_name?.trim() ? item?.full_name.trim() : 'NA'}</td>
                                  <td >{phoneEncripted(item?.mobile ? item?.mobile : "NA")}</td>
                                  <td>{item?.step_name ? item?.step_name : "NA"}</td>
                                  <td className={myValue === "1" ? "mobile-d-none" : ""} onClick={() => { setShareLink({ open: !shareLink.open, selectedItem: item }) }} >
                                    <div className="shere-box">
                                      <img style={{ cursor: "pointer" }} src={ImagePath?.shareImageBlack} />
                                      {shareLink.open && shareLink.selectedItem === item ?
                                        <>
                                          <div class="shere-popup">
                                            <p class="close-btn">x</p>
                                            <div class="d-flex">
                                              <WhatsappShareButton
                                                title={message}
                                                url={refferalData?.link ? refferalData?.link : ""}
                                                className="Demo__some-network__share-button"
                                              >
                                                <WhatsappIcon size={27} round />
                                              </WhatsappShareButton>
                                              <div style={{ alignItems: 'center' }}>
                                                <a href={`tel:${item?.mobile}`} style={{ textDecoration: 'none', marginLeft: '10px' }}>
                                                  <img src={ImagePath.call} alt="user" style={{ width: "25px", height: "25px" }} />
                                                </a>
                                              </div>
                                            </div>
                                          </div>


                                          {/* <div className="d-flex">
                                        <span>{item?.mobile}</span>

                                      </div> */}
                                        </>
                                        :
                                        null}
                                    </div>
                                  </td>

                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        :
                        <p className="text-center" style={{ marginTop: '80px' }}>No data available</p>
                    }
                    {
                      currentItems?.length !== 0 ?
                        <Pagintion currentPage={currentPage} totalPages={totalPagesPL} paginate={handlePageChange} listLength={getUnConvertedLIst && getUnConvertedLIst?.data?.length} itemsPerPage={itemsPerPage} />
                        :
                        null}
                  </div>
                </div>
              </div>

            </div>

            <div className="col-xl-12 refferal_link_section">
              <div className="openTradeChart_wr">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="opentradechart card-height monthly-chart">
                      <div className="tradetitle">
                        <div className="mb-2 mb-md-4 chart-title">
                          <h5 className="d-inline me-2">
                            Monthly Opened and Traded Accounts
                          </h5>
                          <span>
                            <img src={ImagePath.tradeUser} alt="user" />
                          </span>
                        </div>
                      </div>
                      <div className="chart mb-3">
                        {monOpenedTradedChartData.length == 0 || monOpenTradedFilterValue == null ? <div className="text-center mt-center">
                          No data available
                        </div>
                          :
                          <div className="d-sm-block">
                            <MonOpenTradedChart
                              chartData={monOpenedTradedChartData}
                              pathName={CurrentLocation.pathname}
                            />
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-lg-8">
              <div className="income_wr mt-0">
                <div className="row">
                  <div className="tradetitle mb-3 d-flex justify-content-start">
                    <h5 className="d-inline me-3">Business Points OverView</h5>
                    <img src={ImagePath.rupeeIcon} />
                  </div>
                  <div className="col-lg-4">
                    <div className="todays_incom_box" onClick={() => {
                      handleViewMore("/IncomeScreen");
                    }}
                      style={{ cursor: 'pointer' }}>
                      <span >
                        {totalEarning?.totalEarning == "" ? <Loader /> : forNumTOtalEarning}
                      </span>
                      <span>Total Business Points</span>
                    </div>
                    <div className="todays_incom_box" onClick={() => {
                      handleViewMore("/IncomeScreen");
                    }}
                      style={{ cursor: 'pointer' }}>
                      <span>
                        {totalEarning?.totalEarning == "" ? <Loader /> : brokarageEarnValue}
                      </span>
                      <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "#29BADB", padding: "3px", width: "10px", height: "5px", marginRight: "10px" }}></div>
                        <span>Business Points</span>
                      </div>
                    </div>
                    <div className="todays_incom_box" onClick={() => {
                      handleViewMore("/IncomeScreen");
                    }}
                      style={{ cursor: 'pointer' }}>
                      <span>
                        {totalEarning?.totalEarning == "" ? <Loader /> : totalEarning?.totalEarning?.subscription_earning == "0.00" ? 0 : totalEarning?.totalEarning?.subscription_earning}
                      </span>
                      <div style={{ flexDirection: "row", alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <div style={{ backgroundColor: "#FFDE2D", padding: "3px", width: "10px", marginRight: "10px" }}></div>
                        <span>Subscription Points</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="income_left_section">
                    </div>
                    <div className="" style={{ height: '100%' }}>
                      {totalEarning?.totalEarning?.brokerage_earning == 0.00 && totalEarning?.totalEarning?.subscription_earning == 0.00 ?
                        <div className="text-center d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                          No data available
                        </div>
                        :
                        <IncomeOverViewPieChart
                          BrokerageIncome={totalEarning?.totalEarning == "" ? 0 : totalEarning?.totalEarning?.brokerage_earning}
                          SubscriptionIncome={totalEarning?.totalEarning == "" ? 0 : totalEarning?.totalEarning?.subscription_earning}
                          BroSUbTotal={totalEarning?.totalEarning == "" ? 0 : totalEarning?.totalEarning?.earning_with_brok_subs}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ongoingContests">
                <h5 className="ongoingContests-h5">Ongoing Contests</h5>
                <p className="ongoingContests-p">Sit tight! We'll keep you posted on any upcoming contests. Your console is the go-to spot for all the latest competition updates. Stay tuned and be the first to know about new opportunities! Notifications are on their way!"</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        modalValue={isCustomModalOpened}
        customModalFunction={handleCustomModalClose}
        link={refferalData?.link}
        selectedPhoneNumber={selectedPhoneNumber}
      />
    </>

  );
};

export default Dashboard;
