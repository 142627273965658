import React, { useState, useEffect, useRef } from "react";
import "../../src/screens/IncomeScreen.css";
import { ImagePath } from "../helper/ImagePath";
import MonthWiseLeadsChart from "../allCharts/MonthWiseLeadsChart";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getMonthwiseConvUnconvLeads, resetMonthwiseLeads } from "../store/actions";
import {
  createdDatesAscendingSort,
  createdDatesDescendingSort,
  currentMonth,
  emailWrapper,
  last3Months,
  last6Months,
  lastMonths,
  lastYear,
} from "../helper/helpingMethods";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment, { duration } from "moment";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { POST_ACC_OPENING_PROCESS, POST_GET_TOTAL_ACCOUNT_OPENED } from "../api/url_helper";
import Pagintion from "../common/Pagination";
import loaderImg from '../../src/assets/gif/pattern.gif';
import incrpted from "../helper/incrpted";
import decrypted from "../helper/decrpted";

function AllLeads() {
  let duration = localStorage.getItem("@month") == null ? "current_month" : localStorage.getItem("@month")
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [query1, setQuery1] = useState("");
  const [isModalClose, setIsmodalClose] = useState(false);
  const [createdDateSortToggle, setCreatedDateSortToggle] = useState(false);
  const [status, setStatus] = useState('0')
  const isFirstRender = useRef(true);
  const [filterSearchBodyActive, setFilterSearchBodyActive] = useState(false);
  // const [startDateLeadsDetails, setStartDateLeadsDetails] = useState(
  //   localStorage.getItem("@month") == 'last_month' ? lastMonths : localStorage.getItem("@month") == "current_month" ? currentMonth : localStorage.getItem("@month") == 'last_three_month' ? last3Months : localStorage.getItem("@month") == 'last_six_month' ? last6Months : localStorage.getItem("@month") == "current_month" ? currentMonth : localStorage.getItem("@month") == 'last_three_month' ? last3Months : currentMonth
  // );
  const [startDateLeadsDetails, setStartDateLeadsDetails] = useState(
    localStorage.getItem("@month") == 'last_month' ? lastMonths : localStorage.getItem("@month") == 'last_three_month' ? last3Months : localStorage.getItem("@month") == 'last_six_month' ? last6Months : localStorage.getItem("@month") == 'last_three_month' ? last3Months : localStorage.getItem("@month") == "current_month" ? currentMonth : currentMonth
  );
  const [endDateLeadsDetails, setEndDateLeadsDetails] = useState(
    dayjs(new Date())
  );
  const location = useLocation();
  const unconvertedValue = location?.state?.clickedElementLabel || "Converted";
  const [toggleValue, setToggleValue] = useState(unconvertedValue);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);
  const [isLoadingOne, setIsLoadingOne] = useState(false);

  const [state, setState] = useState({
    leadsDetails: [],
    allLeadsLimit: 5,
    allLeadsPage: 1,
    leadsDetailsListCount: 0,
    leadsDetailsList: [],
    filterName: '',
  });
  const [state1, setState1] = useState({
    leadsDetails1: [],
    allLeadsLimit1: 5,
    allLeadsPage1: 1,
    leadsDetailsListCount1: 0,
    leadsDetailsList1: [],
    filterName1: '',
  });
  const convertedPageCount = Math.ceil(state?.leadsDetailsList && state?.leadsDetailsList?.length / state?.allLeadsLimit);
  const unconvertedPageCount = Math.ceil(state1?.leadsDetailsList1 && state1?.leadsDetailsList1?.length / state1?.allLeadsLimit1);
  const [originalData, setOriginalData] = useState([]); // State variable for original data
  const [originalData1, setOriginalData1] = useState([]); // State variable for original data

  const { loginDeatil } = useSelector((state) => ({
    loginDeatil: state.logingData,

  }));
  // const userData = JSON.parse(localStorage.getItem("userData"));
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const decryptedResult = decrypted(userData);

  const uniqueCode = decryptedResult?.username
  // const uniqueCode = loginDeatil?.logingData?.data == "" ? "" : loginDeatil?.logingData?.data[0]?.username
  // const uniqueCode = "H005947"
  const refcode = uniqueCode;
  const isLoading = useSelector(state => state.isLoading);


  useEffect(() => {
    // alert("working on progress")

    dispatch(getMonthwiseConvUnconvLeads({
      'enc': incrpted([{
        duration: duration,
        refcode: refcode,
        client_code: uniqueCode
      }])
      // duration, refcode, token: userData.token 
    }));
    conditions()
  }, [])

  const conditions = () => {
    let start = ""
    let end = ""
    toggleValue == "Converted" ?
      convertedAllApi({
        duration, start
      })
      // dispatch(
      //   getAllLeadsListApi({
      //     duration: duration,
      //     refcode: uniqueCode
      //   })
      // )
      : unConvertedAllApi({ duration, end })
  }

  const { getTotalAccountOpned } = useSelector((state) => ({
    getTotalAccountOpned: state?.getTotalAccountOpned,
  }));

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(state.leadsDetailsListCount / state.allLeadsLimit)) {
      setState((prev) => ({ ...prev, allLeadsPage: newPage }));
    }
  };

  const handlePageChangeun = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(state1.leadsDetailsListCount1 / state1.allLeadsLimit1)) {
      setState1((prev) => ({ ...prev, allLeadsPage1: newPage }));
    }
  };
  //*****************filter chake */
  useEffect(() => {
    if (query === "") {
      setState((prev) => ({
        ...prev,
        leadsDetailsList: originalData,
      }));
    } else {
      const filtered = originalData && originalData?.filter(item =>
        item?.name && item?.name.toLowerCase().includes(query.toLowerCase()) ||
        item?.mobile && item?.mobile.toLowerCase().includes(query.toLowerCase())
      );
      setState((prev) => ({
        ...prev,
        leadsDetailsList: filtered,
        allLeadsPage: 1,
      }));
    }
  }, [query]);
  //***************************unconverted */
  useEffect(() => {
    if (query1 === "") {
      setState1((prev) => ({
        ...prev,
        leadsDetailsList1: originalData1,
      }));
    } else {
      const filtered = originalData1 && originalData1?.filter(item =>
        item?.full_name && item?.full_name.toLowerCase().includes(query1.toLowerCase()) ||
        item?.mobile && item?.mobile.toLowerCase().includes(query1.toLowerCase())
      );
      setState1((prev) => ({
        ...prev,
        leadsDetailsList1: filtered,
        allLeadsPage1: 1,
      }));

    }
  }, [query1]);

  const handleUnconvertedLeadsFilter = (event) => {
    dispatch(resetMonthwiseLeads());
    const selectedValue = event.target.value;
    localStorage.setItem('@month', selectedValue)
    if (selectedValue === "last_three_month") {
      duration = "last_three_month";
      var dateSelectedUser = last3Months
    } else if (selectedValue === "last_six_month") {
      duration = "last_six_month";
      var dateSelectedUser = last6Months
    } else if (selectedValue === "one_year") {
      duration = "one_year";
      var dateSelectedUser = lastYear
    } else if (selectedValue === "last_month") {
      duration = "last_month"
      var dateSelectedUser = duration
    } else if (selectedValue === "current_month") {
      duration = "current_month"
      var dateSelectedUser = duration
    }
    setStartDateLeadsDetails(duration == 'last_month' ? lastMonths : duration == "current_month" ? currentMonth : duration == 'last_three_month' ? last3Months : duration == 'last_six_month' ? last6Months : currentMonth)
    let start = ""
    let end = ""
    dispatch(getMonthwiseConvUnconvLeads({
      'enc': incrpted([{
        duration: duration == "one_year" ? "yearly" : duration,
        refcode: uniqueCode,
        client_code: uniqueCode
      }])
    }));
    toggleValue == "Converted" ?
      convertedAllApi({ duration, start })
      : unConvertedAllApi({ duration, end })

  };

  useEffect(() => {
    setFilterSearchBodyActive(false);
  }, [isModalClose]);

  const handleButtonClick = (buttonType) => {
    const duration = "custom_date_range";
    let start = moment(startDateLeadsDetails).format("YYYY-MM-DD")
    let end = moment(endDateLeadsDetails).format("YYYY-MM-DD")
    setToggleValue(buttonType);
    setState1((prev) => ({ ...prev, allLeadsPage1: 1 }));
    setState((prev) => ({ ...prev, allLeadsPage: 1 }));
    buttonType == "Converted" ?
      convertedAllApi({ duration, start, end })
      : unConvertedAllApi({ duration, start, end })
  };

  const handleRecivedData = (receivedData) => {
    setIsmodalClose(!receivedData);
  };

  const handleCreatedDatesSorting = () => {
    if (createdDateSortToggle) {
      const sortedData = createdDatesAscendingSort(state.leadsDetails);
      setState((prev) => ({
        ...prev,
        leadsDetails: sortedData,
      }));

      setCreatedDateSortToggle(!createdDateSortToggle);
    } else {
      const sortedData = createdDatesDescendingSort(state.leadsDetails);
      setState((prev) => ({
        ...prev,
        leadsDetails: sortedData,
      }));

      setCreatedDateSortToggle(!createdDateSortToggle);
    }
  };

  const onChangeStartDate = (date) => {
    setStartDateLeadsDetails(moment(date.$d).format("YYYY-MM-DD"));
    const duration = "custom_date_range";
    let start = moment(date.$d).format("YYYY-MM-DD")
    let end = moment(endDateLeadsDetails).format("YYYY-MM-DD")
    if (toggleValue == "Converted") {
      convertedAllApi({ duration, start, end })
    }
    else {
      unConvertedAllApi({ duration, start, end })
    };
  }

  const onChangeToDate = (date) => {
    setEndDateLeadsDetails(date.$d);
    const duration = "custom_date_range";
    let start = moment(startDateLeadsDetails).format("YYYY-MM-DD")
    let end = moment(date.$d).format("YYYY-MM-DD")
    if (toggleValue == "Converted") {
      convertedAllApi({ duration, start, end })
    }
    else {
      unConvertedAllApi({ duration, start, end })
    };
  }

  const convertedAllApi = ({ duration, start, end }) => {
    setIsLoadingTwo(true);
    const apiUrl = POST_GET_TOTAL_ACCOUNT_OPENED;
    const payload = {
      'enc': incrpted([{
        duration: duration,
        refcode: uniqueCode,
        start_date: duration == "custom_date_range" ? start : "",
        end_date: duration == "custom_date_range" ? end : "",
        limit: 5,
        page: state?.allLeadsPage,
        client_code: uniqueCode
      }])
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post(apiUrl, payload, { headers })
      .then(response => {
        setState((prev) => ({
          ...prev, leadsDetailsList: response?.data?.account_open_list,
        }));
        setState((prev) => ({
          ...prev,
          leadsDetailsListCount: response?.data?.account_opened_count,
        }));
        setOriginalData(response?.data?.account_open_list)
        setIsLoadingTwo(false)
      })
      .catch(error => {
        console.error('Error:', error);
      }).finally(() => {
        setIsLoadingTwo(false); // Set isLoading to false after API call
      });
  }

  const unConvertedAllApi = ({ duration, start, end }) => {
    setIsLoadingOne(true);
    const apiUrl = POST_ACC_OPENING_PROCESS
    const payload = {
      'enc': incrpted([{
        duration: duration,
        refcode: uniqueCode,
        start_date: duration == "custom_date_range" ? start : "",
        end_date: duration == "custom_date_range" ? end : "",
        limit: 5,
        page: state1?.allLeadsPage1,
        client_code: uniqueCode
      }])

    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post(apiUrl, payload, { headers })
      .then(response => {
        setState1((prev) => ({
          ...prev, leadsDetailsList1: response?.data?.data,
        }));
        let datalength = response?.data?.data?.length
        setState1((prev) => ({
          ...prev,
          leadsDetailsListCount1: datalength,
        }));
        setOriginalData1(response?.data?.data)
        setIsLoadingOne(false)
      })
      .catch(error => {
        console.error('Error:', error);
      }).finally(() => {
        setIsLoadingOne(false); // Set isLoading to false after API call
      });
  }

  const phoneEncripted = (phoneNumber) => {
    return phoneNumber.replace(/(\d{6})(\d{4})/, "******$2");
  };

  return (
    <>
      <div className="container main-dive m-px-30 all-leads-screen-page">
        <div className="row income_cal_wr mt-4 px-2">
          <div className="d-flex monthly_cat_selet pe-3">
            <div className="mb-3">
              <h4>Leads</h4>
              <span className="f-14-label">Month on month converted vs unconverted leads </span>
            </div>
            <div className="lead_filter mx-0 mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(event) => handleUnconvertedLeadsFilter(event)}
                value={localStorage.getItem("@month") == "current_month" ? "current_month" : localStorage.getItem("@month") == "last_six_month" ? "last_six_month" : localStorage.getItem("@month") == "last_three_month" ? "last_three_month" : localStorage.getItem("@month") == "last_month" ? "last_month" : "current_month"}
              >
                <option value="current_month">Current Month</option>
                <option value="last_month">Last Month</option>
                <option value="last_three_month" >Last 3 Months</option>
                <option value="last_six_month">
                  Last 6 Months
                </option>
                {/* <option value="one_year">Last 12 Months</option> */}
              </select>
            </div>
          </div>
          {/* 
          <div className="row pt-2"> */}
          <div className="col-12 p-0">
            <div className="table-responsive">
              <MonthWiseLeadsChart />
            </div>
          </div>
          {/* </div> */}

        </div>
        <div className="row pt-4 mb-5">
          <div className="clien_wise_text bg-white px-3">
            <div className=" d-flex monthly_cat_select flex-wrap">
              <div className="d-flex pe-3 ">
                <h4>Lead Details</h4>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-3 mb-2"
                style={{ alignItems: "center", display: "grid", }}
              >
                <div className="search_bar search_name_no">
                  <input
                    className="me-1"
                    type="search"
                    placeholder="Search by Name, Mobile no"
                    aria-label="Search"
                    onChange={(e) => toggleValue == "Converted" ? setQuery(e.target.value) : setQuery1(e.target.value)}

                  />
                  <img
                    className="img img-fluid"
                    src={ImagePath.search}
                    alt="search"
                  />
                </div>

              </div>
              <div className="col-lg-9">
                <div className="d-flex calendar_wr flex-wrap align-items-center">
                  <div className="date_picker_wr me-2" style={{ marginBottom: "27px" }}>
                    <div className="date_picker me-1">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From Date"
                          format="DD/MM/YYYY"
                          value={dayjs(startDateLeadsDetails)}
                          onChange={onChangeStartDate}
                          maxDate={dayjs(new Date())}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="date_picker ms-1 ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To Date"
                          format="DD/MM/YYYY"
                          value={endDateLeadsDetails}
                          maxDate={dayjs(new Date())}
                          onChange={onChangeToDate}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="income_client col-12 col-md-4">
                    <button
                      className={toggleValue === "Converted" ? "selected" : ""}
                      onClick={() => { handleButtonClick("Converted") }}
                      style={{ width: "50%" }}
                    >
                      Converted
                    </button>
                    <button
                      className={toggleValue === "Unconverted" ? "selected" : ""} style={{ width: "50%" }}
                      onClick={() => { handleButtonClick("Unconverted") }}
                    >
                      Unconverted
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {toggleValue == "Converted" ?
            <div className="client_wise_income_table_wr ">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => handleCreatedDatesSorting()}>
                        Account Opening Date
                      </th>
                      <th cope="col" onClick={() => { }}>
                        Customer Name
                      </th>
                      <th cope="col">Mobile Number</th>
                      <th cope="col">Email</th>
                      <th cope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingTwo == true ?
                      (
                        <tr>
                          <td colspan="5" className="text-center">
                            <img src={loaderImg} alt="Loading..." className='loader-img ' style={{ width: '150px', height: 'auto', margin: 'auto' }} />
                          </td>
                        </tr>
                      ) : <>
                        {state?.leadsDetailsList?.length !== 0 ?
                          state?.leadsDetailsList.slice(
                            (state.allLeadsPage - 1) * state.allLeadsLimit,
                            state.allLeadsPage * state.allLeadsLimit
                          ).map((item, i) => {
                            return (
                              <tr>
                                <td>{item?.my_etrades_opendate ? moment(item?.my_etrades_opendate).format("DD-MM-YYYY") : "NA"} </td>
                                <td>{item?.name == null ? "NA" : item?.name}</td>
                                <td>{phoneEncripted(item?.mobile ? item?.mobile : "NA")}</td>
                                <td>{item?.email == null ? "NA" : emailWrapper(item?.email)}</td>
                                <td>{"Converted"}</td>
                              </tr>
                            );
                          })
                          :
                          <tr>
                            <td colSpan="5" className="text-center"> {/* Setting colspan to span across all columns */}
                              <span>No data found</span>
                            </td>
                          </tr>
                        }
                      </>
                    }

                  </tbody>
                </table>
              </div>
              {/* {state?.leadsDetailsList?.length === 0 ? (<GlobalLoader />) :
                <Pagintion currentPage={state?.allLeadsPage} totalPages={convertedPageCount} paginate={handlePageChange} listLength={state?.leadsDetailsList && state?.leadsDetailsList?.length} itemsPerPage={state?.allLeadsLimit} />
              } */}
              {state?.leadsDetailsList?.length > 0 ?
                (
                  <Pagintion currentPage={state?.allLeadsPage} totalPages={convertedPageCount} paginate={handlePageChange} listLength={state?.leadsDetailsList && state?.leadsDetailsList?.length} itemsPerPage={state?.allLeadsLimit} />
                ) :
                null
              }
            </div>
            :

            <div className="client_wise_income_table_wr ">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => handleCreatedDatesSorting()}>
                        Created Date
                      </th>
                      <th cope="col" onClick={() => { }}>
                        Customer Name
                      </th>
                      <th cope="col">Mobile Number</th>
                      <th cope="col">Email</th>
                      {/* <th cope="col">Status</th> */}
                      <th cope="col">Stage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingOne == true ?
                      (
                        <tr>
                          <td colspan="5" className="text-center">
                            <img src={loaderImg} alt="Loading..." className='loader-img ' style={{ width: '150px', height: 'auto', margin: 'auto' }} />
                          </td>
                        </tr>
                      ) : <>
                        {state1?.leadsDetailsList1 && state1?.leadsDetailsList1.length > 0 ? (
                          state1?.leadsDetailsList1
                            .slice(
                              (state1?.allLeadsPage1 - 1) * state1?.allLeadsLimit1,
                              state1?.allLeadsPage1 * state1?.allLeadsLimit1
                            )
                            .map((item, i) => (
                              <tr key={i}>
                                <td>{item?.created_at ? moment(item?.created_at).format("DD-MM-YYYY") : "NA"}</td>
                                <td>{item?.full_name == null || item?.full_name == "  " ? "NA" : item?.full_name}</td>
                                <td>{phoneEncripted(item?.mobile ? item?.mobile : "NA")}</td>
                                <td>{item?.email == null ? "NA" : emailWrapper(item?.email)}</td>
                                {/* <td>{"Unconverted"}</td> */}
                                <td>{item?.step_name ? item?.step_name : "NA"}</td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">No data found</td>
                          </tr>
                        )}
                      </>}
                  </tbody>

                </table>
              </div>
              {/* {state1?.leadsDetailsList1 && state1?.leadsDetailsList1.length > 0 ?
                <Pagintion currentPage={state1?.allLeadsPage1} totalPages={unconvertedPageCount} paginate={handlePageChangeun} listLength={state1?.leadsDetailsList1 && state1?.leadsDetailsList1?.length} itemsPerPage={state1?.allLeadsLimit1} />
                :
                (<GlobalLoader />)
              } */}
              {state1?.leadsDetailsList1 && state1?.leadsDetailsList1.length > 0 ?
                (
                  <Pagintion currentPage={state1?.allLeadsPage1} totalPages={unconvertedPageCount} paginate={handlePageChangeun} listLength={state1?.leadsDetailsList1 && state1?.leadsDetailsList1?.length} itemsPerPage={state1?.allLeadsLimit1} />
                )
                : null
              }
            </div>
          }
        </div>
      </div>
    </>
  );
}
export default AllLeads;

