import React from 'react';
import CryptoJS from "crypto-js";
import { PAYMENT_key } from '../api/url_helper';

const decrypted = (encrypted_data) => {
    var CryptoJSAesJson = {
        stringify: function (cipherParams) {
            var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
            return cipherParams;
        }
    };

    try {
        // Decode base64
        const decoded = atob(encrypted_data);
        
        // Decrypt the data using the same key
        var decrypted = CryptoJS.AES.decrypt(decoded, PAYMENT_key, { format: CryptoJSAesJson });
        
        // Convert to string and parse JSON
        var decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
        
        // Parse the JSON string to get the original data
        var originalData = JSON.parse(decryptedString);
        
        // Convert the string back to an object if it's URL encoded
        const params = new URLSearchParams(originalData);
        const resultObject = {};
        
        // Convert URL params to object
        for (const [key, value] of params.entries()) {
            resultObject[key] = value;
        }
        
        return resultObject;
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
};

export default decrypted;