const BIGUL_PAY_URL = "http://14.141.71.10:9494/bigulpay/";

const BIGUL_CLIENT_DASHBOARD_URL = "https://client-dasboardapis.bigul.app/";
const BIGUL_CO = "https://bigul.co/api/";
// const DKYC_BONANZA_API = "https://dkyc.bonanzaonline.com/ekycapi/";
const DKYC_BONANZA_API = "https://apinew.bigul.app:4040/api/v1";
const DKYC_BONANZA_API_LOGIN = "https://dkyc.bonanzaonline.com/ekycapi/";
const EKYC_BIGUL_API_LOGIN = "https://ekycapi.bigul.co/";
export const PAYMENT_key = "475681656b2d6ed5bcb51d6dd772fa4f";

export const GET_TOTAL_ACCOUNT =
  BIGUL_PAY_URL +
  "paymentapi_uat.php?fn=getNewClientsMyetrade&incode=ALL&FromDate=2023-09-01&ToDate=2023-09-01";

// https://dkyc.bonanzaonline.com/ekycapi/UserLogin

// export const POST_LOGIN = EKYC_BIGUL_API_LOGIN + "UserLogin";
export const POST_LOGIN = "https://apinew.bigul.app:4040/api/v1/login-with-otp";
export const OTP_CHECK = "https://apinew.bigul.app:4040/api/v1/verify-otp";

export const POST_REFERRAL_LINK = BIGUL_CO;
export const POST_GET_SUBSCRIPTION_LIST = DKYC_BONANZA_API;
export const GET_TOATL_EARNING = BIGUL_PAY_URL + "paymentapi_uat.php?";

export const GET_ACCOUNT_TRADE_EARN = BIGUL_PAY_URL + "paymentapi_uat.php";
// export const GET_ACCOUNT_TRADE_EARN = DKYC_BONANZA_API + "getTotalEarning";


export const GET_TOTAL_EARN_MONTH_WISE = BIGUL_PAY_URL + "paymentapi_uat.php";

export const GET_TOTAL_TRADE_ACCOUNT = BIGUL_PAY_URL + "paymentapi_uat.php";

export const POST_MONTH_WISE_CONVERTED_UNCONVERTED_LEADS =
  DKYC_BONANZA_API + "/getMonthWiseLeads";

export const POST_LEADS_DETAIL = DKYC_BONANZA_API + "/getLeads";

export const GET_RECOMMENDED_ALGOS = BIGUL_CLIENT_DASHBOARD_URL;

export const POST_LEAD_SUMMARY_CONV_UNCONVERTED =
  DKYC_BONANZA_API + "/kyc_and_dropouts";

export const POST_ALL_UNCONVERTED_LEADS =
  DKYC_BONANZA_API + "/getAllUnconvertedLeads";

export const POST_STAGEWISE_UNCONVERTED_LEADS =
  DKYC_BONANZA_API + "/stagewise_dropout";

export const POST_ACC_OPENING_PROCESS =
  DKYC_BONANZA_API + "/getAllUnconvertedLeadsNew";
export const POST_GET_TRADED_OPNE_ACCOUNT = DKYC_BONANZA_API + "/getTradedOpenedAccount"
export const POST_TOTAL_EARNING = DKYC_BONANZA_API + "/getTotalEarning";
export const POST_GET_TOTAL_ACCOUNT_OPENED = DKYC_BONANZA_API + "/getTotalAccountOpened";
export const POST_GET_BROKERAGE_CLIENT_LIST = DKYC_BONANZA_API + "/getBrokerageClientList";
export const POST_GET_SUBSCRIPTION_LIST_INCOME = DKYC_BONANZA_API + "/getSubscriptionList"
