import React from 'react';
import CryptoJS from "crypto-js";
import { PAYMENT_key } from '../api/url_helper';
import decrypted from "../helper/decrpted";

// Functional component
const incrpted = (Dynamic_arr) => {
    var CryptoJSAesJson = {
        stringify: function (cipherParams) {
            var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
            return cipherParams;
        }
    };

    var key = PAYMENT_key;

    // var dataUser = JSON.parse(localStorage.getItem("userData"))
    const dataUser = JSON.parse(sessionStorage.getItem("userData"));
    const decryptedResult = decrypted(dataUser);

    var token = decryptedResult == null ? "1234" : decryptedResult.token

    var enc_data = 'client_token=' + token + `&client_type=${'Partner'}`;
    // if (Dynamic_arr.length > 0) {
    //     Dynamic_arr.forEach((item, index) => {
    //         for (const [key, value] of Object.entries(item)) {
    //             // if (key == 'client_code') { // Skip client_code if already present
    //             enc_data += `&${key}=${value}`;
    //             // }
    //         }
    //     });
    // }
    if (Array.isArray(Dynamic_arr) && Dynamic_arr.length > 0 && Dynamic_arr[0] !== null) {
        Dynamic_arr.forEach((item, index) => {
            if (item && typeof item === 'object') { // Ensure item is an object
                for (const [key, value] of Object.entries(item)) {
                    // Process key-value pairs
                    enc_data += `&${key}=${value}`;
                }
            }
        });
    } else {
        console.log("Dynamic_arr is either empty or contains invalid data.");
    }

    // Always include client_token


    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(enc_data), key, { format: CryptoJSAesJson }).toString();
    var client_code_new = btoa(encrypted);
    return client_code_new;
}

export default incrpted;